@import url('https://fonts.googleapis.com/css2?family=Passions+Conflict&display=swap');

body {
  margin: 0;
  font-family: 'Passions Conflict', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: azure;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box1 {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  background-color: powderblue;
}

h1 {
  font-size: 30px;
  font-weight: bold;
  color: orange;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
}

p {
  margin: 5px !important;
  padding: 0;
}

.car {
  border-radius: 5px;
  margin-top: 100px;
  height: 160px;
  transition: 0.3s;
}

.car:hover {
  height: 240px;
}

.car:hover>.car-content {
  opacity: 1;
  transform: translateY(-40px)
}

.header {
  padding: 8px;
  background-color: rgb(29, 134, 225);
}

.header img {
  float: left;
  width: 40px;
  height: 40px;
  background: #555;
}

.header .ant-btn{
  box-shadow: none !important;
  background-color : orangered !important;
  color: white !important;
  border: 1px solid orangered !important;
  padding: 5px 15px !important;
  outline: none !important;
}

.content {
  min-height: 97vh;
  padding: 10px;
}

.carimg {
  height: 170px;
  width: auto;
  border-radius: 5px;
  transform: translateY(-50px);
}

.car-content {
  transform: translateY(-200px);
  opacity: 0;
  transition: 0.3s;
}

.btn1 {
  box-shadow: none !important;
  background-color: green !important;
  color: white !important;
  border: 1px solid green !important;
  padding: 5px 15px !important;
  outline: none !important;
}

.btn1:focus {
  border: none;
}

.login {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login label,
.login h1 {
  color: black !important;
  opacity: 0.7;
}

.login-form {
  width: 400px;
	height: 510px;
  background-color: lightblue;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.wallpaper{
  background-color: wheat;
}
.login-form input password {
  background-color: black !important;
  border: none !important;
  color: white;
  opacity: 0.6;
}

.login a {
  color: black !important;
  opacity: 0.7;
}

.policyimg2{
  height: 350px;
  border-radius: 10px;
  width: auto;
}

svg{
  height: 18px ;
  width: 18px ;
}
.close{
  height: 18px ;
  width: 18px ;
}
